<template>
  <div>
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
      <h4 id="top">Activity Reports</h4>
    </div>

    <div v-if="timeCards" class="card">
      <div class="rainbow-background"></div>
      <div class="row m-4">
        <div class="col-3">
          <datepicker id="week_starting" input-class="date-picker" @search="searchWeeksStarting" :clearSearchOnSelect="true" :disabled-dates="this.state.disabledStartDates" :resetOnOptionsChange="true" label="weeks_ending" v-model="selectedWeeksStarting" placeholder="Select Week Starting Date" format="MM-dd-yyyy" ></datepicker>
        </div>
        <div class="col-3">
          <v-select :options="users" @search="searchUsers" :clearSearchOnSelect="true" label="full_name" v-model="searchedUserName" placeholder="Select User" :clearable="false"/>
        </div>
        <div class="col-3">
          <v-select :options="statuses" @change="searchStatuses(selectedStatus)" :clearSearchOnSelect="true" label="full_name" v-model="selectedStatus" placeholder="Select Status" :searchable="false" :clearable="false"/>
        </div>
        <div class="col-3">
          <button class="btn btn-danger" @click="clearFilters">Clear Filters</button>
        </div>
      </div>

      <div class="table-responsive">
        <table class="card-table table">
          <thead>
          <tr>
            <th class="w-14">Week Start Date</th>
            <th class="w-14">Week End Date</th>
            <th class="w-20">Name</th>
            <th class="w-14">Status</th>
            <th class="w-14">Total Days</th>
            <th class="w-14"/>
          </tr>
          </thead>
          <tbody>
          <tr v-for="timeCard in timeCards" :key="timeCard.id" :id="timeCard.id" :class="determineStatusClass(timeCard)">
            <td class="w-14">
              {{ formattedDate(timeCard.week_start_date, 1) }}
            </td>
            <td class="w-14">
              {{ formattedDate(timeCard.week_start_date, 7) }}
            </td>
            <td class="w-20">
              {{ timeCard.user.full_name }}
            </td>
            <td class="w-14">
              <b-badge :variant="determineStatus(timeCard.status)">{{ timeCard.status }}</b-badge>
            </td>
            <td class="w-14">
              {{ timeCard.calculate_total_days }}
            </td>
            <td class="w-14">
              <div class="btn-group center">
                <router-link v-if="timeCard.id" :to="{ name: 'TimeManagementReview', params: { id: timeCard.id} }" tag="button" class="btn btn-primary" type="button">Review</router-link>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <!-- Pagination -->
      <pagination :totalRows="totalRows" :page="currentPage" :numOfRecords="perPage" @onChange="onChange" />

    </div>

    <loading-overlay v-else></loading-overlay>

  </div>
</template>

<script>
import { TimeCardService } from '@/common/services/time_card.service';
import { UserService } from '@/common/services/user.service';
import Pagination from '@/common/components/pagination.vue';
import { FilterService } from '@/common/services/filter.service';
import HelperMethods from '@/common/mixins/helper_methods';
import LoadingOverlay from '@/common/components/loading-overlay.vue';
import JwtService from '@/common/services/jwt.service';
import _ from 'lodash';
import Datepicker from 'vuejs-datepicker';

export default {
  name: 'TimeManagementIndex',
  mixins: [HelperMethods],
  components: {
    Pagination,
    LoadingOverlay,
    Datepicker,
  },
  data() {
    return {
      timeCards: null,
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      totalRecords: 0,
      filters: FilterService.defaultData(),
      jwt: JwtService.parseJwt(),
      isAdmin: null,
      approvedTimeCards: [],
      weeksStarting: [],
      searchedWeekEnding: '',
      searchWeekEndingResults: [],
      selectedWeeksStarting: null,
      users: [],
      selectedUser: null,
      searchedUserName: null,
      statuses: [],
      selectedStatus: null,
      state: {
        disabledStartDates: {
          days: [1, 2, 3, 4, 5, 6],
        },
      },
    };
  },
  created() {
    this.isAdmin = this.jwt.role === 'Admin';
    this.getTimeCards(this.currentPage, this.perPage, false);
    this.getStatuses();
    this.getUsers();
    this.selectedWeeksStarting = this.getWeekStart();
  },
  methods: {
    searchStatuses(status) {
      this.filters.status_eq = status;
      const params = FilterService.build({ page: this.currentPage, per_page: this.perPage }, this.filters);
      TimeCardService.query(params).then((response) => {
        this.currentPage = parseFloat(response.headers['x-page']);
        this.totalRows = parseFloat(response.headers['x-total']);
        this.timeCards = response.data;
      });
    },
    getStatuses() {
      this.statuses = [
        'Submitted',
        'In Progress',
        'Resubmitted',
      ];
    },
    getUsers(page, perPage) {
      const params = FilterService.build({ page, per_page: perPage }, this.filters);
      UserService.query(params).then((response) => {
        this.currentPage = parseFloat(response.headers['x-page']);
        this.totalRows = parseFloat(response.headers['x-total']);
        this.users = response.data;
      });
    },
    searchUsers(search, loading) {
      // This is called when the user enters anything in the v-select field, and calls the below search method
      loading(true);
      this.searchedUserName = search;
      this.userSearch(loading, search, this);
    },
    userSearch: _.debounce((loading, search, self) => {
      const selfRef = self;
      // This method makes an api request on Tags looking for a tag name containing the search param
      const params = FilterService.build({ page: 1, per_page: 10 }, { first_name_or_last_name_cont: search, role_end: 'Coach' });
      UserService.query(params)
        .then(({ data }) => {
          selfRef.users = data;
          loading(false);
        });
    }, 350),
    searchWeeksStarting(search, loading) {
      // This is called when the user enters anything in the v-select field, and calls the below search method
      loading(true);
      this.searchedWeekEnding = search;
      this.search(loading, search, this);
    },
    search: _.debounce((loading, search, self) => {
      const selfRef = self;
      selfRef.selectedWeeksStarting = search;
      // selfRef.filters.status_not_eq = 'Approved';
      selfRef.filters.week_start_date_cont = String(search);
      const params = FilterService.build({ page: 1, per_page: 10 }, selfRef.filters);
      TimeCardService.query(params)
        .then(({ data }) => {
          selfRef.timeCards = data;
          loading(false);
        });
    }, 350),
    approveSelected() {
      const checkboxes = document.getElementsByClassName('check-select');

      _.forEach(checkboxes, (value) => {
        if (value.checked) {
          const timeCardStatus = value;
          const timeCard = this.timeCards.find(tc => tc.id === parseFloat(timeCardStatus.id));
          timeCard.status = 'Approved';
          TimeCardService.update(timeCard.id, timeCard)
            .then(() => {
              this.getTimeCards(this.currentPage, this.perPage, true);
            });
        }
      });
    },
    onChange(page, perPage) {
      this.getTimeCards(page, perPage, false);
    },
    getTimeCards(page, perPage, clearFilters) {
      if (clearFilters) {
        this.clearFilters();
        this.filters = FilterService.defaultData();
      }
      this.filters.role_end = 'Coach';
      this.filters.s = 'week_start_date desc';
      const params = FilterService.build({ page, per_page: perPage }, this.filters);
      TimeCardService.query(params).then((response) => {
        this.currentPage = parseFloat(response.headers['x-page']);
        this.totalRows = parseFloat(response.headers['x-total']);
        this.timeCards = response.data;
        const newestWeeksStarting = [...new Set(this.timeCards[0].determine_all_start_dates.map(w => w))];
        this.weeksStarting = newestWeeksStarting.sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
      });
    },
    setFilters(filters) {
      this.filters = filters;
      this.getTimeCards(this.currentPage, this.perPage, false);
    },
    determineStatusClass(timeCard) {
      switch (timeCard.status) {
        case 'Approved':
          return 'approved';
        case 'In Progress':
          return 'in-progress';
        case 'Submitted':
          return 'submitted';
        case 'Rejected':
          return 'rejected';
        default:
          return 'in-progress';
      }
    },
    determineStatus(status) {
      switch (status) {
        case 'Approved':
          return 'success';
        case 'In Progress':
          return 'primary';
        case 'Submitted':
          return 'warning';
        case 'Rejected':
          return 'danger';
        default:
          return 'primary';
      }
    },
    clearFilters() {
      this.timeCards = null;
      this.filters = FilterService.defaultData();
      this.selectedWeeksStarting = null;
      this.selectedStatus = null;
      this.selectedUser = null;
      this.searchedUserName = null;
      this.status = null;
      this.getTimeCards(this.currentPage, this.perPage, false);
    },
  },
  watch: {
    selectedWeeksStarting(value) {
      let valueString = '';
      if (value != null && typeof value !== 'string') valueString = this.getDateString(value);
      this.filters = FilterService.defaultData();
      const weekSelected = valueString;

      if (weekSelected && this.searchedUserName) {
        this.filters.week_start_date_eq = String(valueString);
        this.filters.user_id_eq = String(this.searchedUserName.id);
      } else if (weekSelected) {
        this.filters.week_start_date_eq = String(valueString);
        this.filters.week_start_date_cont = String(valueString);
      } else if (this.searchedUserName) {
        this.filters.user_id_eq = String(this.searchedUserName.id);
      }

      if (this.status) {
        this.filters.status_eq = this.status;
      }

      this.filters.role_end = 'Coach';
      this.filters.s = 'week_start_date desc';
      this.currentPage = 1;
      const params = FilterService.build({ page: this.currentPage, per_page: this.perPage }, this.filters);
      TimeCardService.query(params)
        .then((response) => {
          this.currentPage = parseFloat(response.headers['x-page']);
          this.totalRows = parseFloat(response.headers['x-total']);
          this.timeCards = response.data;
        });
    },
    searchedUserName(value) {
      let startingWeek = null;
      this.filters = FilterService.defaultData();

      if (value) {
        this.searchedUserName = value;
      }
      if (value && this.selectedWeeksStarting) {
        if (typeof this.selectedWeeksStarting !== 'string') startingWeek = this.getDateString(this.selectedWeeksStarting);
        else startingWeek = this.selectedWeeksStarting;
        this.filters.user_id_eq = value.id;
        this.filters.week_start_date_eq = startingWeek;
      } else if (value) {
        this.filters.user_id_eq = value.id;
      } else if (this.selectedWeeksStarting) {
        startingWeek = this.getDateString(this.selectedWeeksStarting);
        this.filters.week_start_date_eq = startingWeek;
      }

      if (this.status) {
        this.filters.status_eq = this.status;
      }

      this.filters.role_end = 'Coach';
      this.filters.s = 'week_start_date desc';
      this.currentPage = 1;
      const params = FilterService.build({ page: this.currentPage, per_page: this.perPage }, this.filters);
      TimeCardService.query(params)
        .then((response) => {
          this.currentPage = parseFloat(response.headers['x-page']);
          this.totalRows = parseFloat(response.headers['x-total']);
          this.timeCards = response.data;
        });
    },
    selectedStatus(status) {
      let startingWeek = null;
      this.filters = FilterService.defaultData();
      this.status = status;

      if (this.searchedUserName && this.selectedWeeksStarting) {
        startingWeek = this.getDateString(this.selectedWeeksStarting);
        this.filters.user_id_eq = this.searchedUserName.id;
        this.filters.week_start_date_eq = startingWeek;
      } else if (this.searchedUserName) {
        this.filters.user_id_eq = this.searchedUserName.id;
      } else if (this.selectedWeeksStarting) {
        startingWeek = this.getDateString(this.selectedWeeksStarting);
        this.filters.week_start_date_eq = startingWeek;
      }

      if (this.status) {
        this.filters.status_eq = this.status;
      }

      this.filters.role_end = 'Coach';
      this.filters.s = 'week_start_date desc';
      this.currentPage = 1;
      const params = FilterService.build({ page: this.currentPage, per_page: this.perPage }, this.filters);
      TimeCardService.query(params)
        .then((response) => {
          this.currentPage = parseFloat(response.headers['x-page']);
          this.totalRows = parseFloat(response.headers['x-total']);
          this.timeCards = response.data;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../styles/views/timecard";
</style>
